import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import Aboutsec from "../../Components/Aboutsec/Aboutsec";
import Banner from "../../Components/Banner/Banner";
import Faqs from "../../Components/Faqs/Faqs";
import Advantages from "../../Components/Advantages/Advantages";
import Roadmap from "../../Components/Roadmap/Roadmap";
import chatDefi from "../../Assets/images/v2.1.mp4";
import toast, { Toaster } from "react-hot-toast";
import Comingsoonpage from "../../Components/Comingsoon/Comingsoonpage";
import ReactPlayer from "react-player";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const videoRef = useRef(null);
  const timeline = useRef(gsap.timeline({ paused: true }));
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    // Define your timeline animation
    timeline.current.to(videoRef.current, {
      opacity: 1,
      duration: 1,
      // Add other animations or properties as needed
    });
  }, []);

  const handleScroll = () => {
    // Your logic for handling scroll
    timeline.current.play();
  };

  const handleHover = () => {
    // Your logic for handling hover
    setHovered(true);
    timeline.current.play();
  };
  return (
    <>
      <Banner />
      <Aboutsec />
      <Advantages />
      <Comingsoonpage />
      <section className="chatDefiMain" id="tokenomicsSrl">
        <div className="container">
          <div className="titlebox">
            <h2>Tokenomics</h2>
            <p>Read our Tokenomics here.</p>
          </div>
          <div className="tokemonicsec">
            <div
              ref={videoRef}
              onScroll={handleScroll}
              onMouseEnter={handleHover}
              onMouseLeave={() => setHovered(false)}
              className='imageBoxChart text-center'
            >
              <video
              className="color-changing-element"
                ref={videoRef}
                src={chatDefi}
                width="100%"
                height="100%"
                muted
                playsInline
                autoPlay
                style={{ opacity: 1     }} // Initially hide the video element
              />
            </div>
            <div className="tokenomicsTable">
              <table>
                <tr>
                  <th colSpan={2} width="100%">
                    Token Details
                  </th>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>DeFiWorld</td>
                </tr>
                <tr>
                  <td>Symbol</td>
                  <td>DWC</td>
                </tr>
                <tr>
                  <td>Total Supply</td>
                  <td>10,000,000 DWC</td>
                </tr>
                <tr>
                  <td>Contract Address</td>
                  <td>
                    <a
                      href="https://polygonscan.com/token/0x96F0a7167ab7ba6e59FfB68707C9d1B049524B0F"
                      className="linktable"
                      rel="noreferrer"
                      target={"_blank"}
                    >
                      0x96F0...24B0F
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Blockchain</td>
                  <td>Polygon Mainnet</td>
                </tr>
                {/* <tr>
                                <td>Quick Add</td>
                                <td>
                                    <button className='defi_btn_cst' onClick={async () => {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_watchAsset',
                                                params: {
                                                    type: 'ERC20',
                                                    options: {
                                                        address: "0x6032e93ddc9ef65212a9bde047a93e5d16126c8e",
                                                        symbol: "Defi",
                                                        decimals: 18,
                                                        image: "https://DefiWorld./favicon.webp"
                                                    },
                                                },
                                            });
                                            console.log('Token added successfully!');
                                        } catch (error) {
                                            // show toast with text "Unable to add Defi"
                                            toast.error('Unable to add Defi');
                                        }
                                    }}>Add Defi</button>
                                    <button className='defi_btn_cst' onClick={async () => {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [
                                                    {
                                                        chainId: '0x89', // Chain ID for Polygon Mainnet
                                                        chainName: 'Polygon Mainnet',
                                                        nativeCurrency: {
                                                            name: 'MATIC',
                                                            symbol: 'MATIC',
                                                            decimals: 18,
                                                        },
                                                        rpcUrls: ['https://polygon-mainnet.rpc.url'], // Replace with the actual RPC endpoint for Polygon Mainnet
                                                        blockExplorerUrls: ['https://polygonscan.com'], // Replace with the actual block explorer URL for Polygon Mainnet
                                                    },
                                                ],
                                            });
                                            console.log('Polygon Mainnet added successfully!');
                                        } catch (error) {
                                            // show toast with text 'Error adding Polygon Mainnet:'
                                            toast.error('Error adding Polygon Mainnet:');
                                        }
                                    }}>Add Polygon</button>
                                </td>
                            </tr> */}
              </table>
            </div>
          </div>
        </div>
      </section>
      <Roadmap />
      <Faqs />
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default Home;
